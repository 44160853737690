import {useLocalization} from "../../hooks/useLocalization";
import {useLocation, useNavigate} from "react-router-dom";
import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {
    addDaysToDate,
    closeModal,
    decodeToBase64AndUri,
    formatDateMarket,
    openModal,
    parseMobileKeyToJson,
    validateEmail
} from "../../components/Utility";
import useAuth from "../../hooks/useAuth";
import {url} from "../../routes/utility";
import {CheckoutInfo, CheckoutInfoSticky, CreditCard, Invoice} from "./components";
import {MarketSourceTypes, MemberStatus, SalesTypes, SourceTypes} from "../../components/Constants";
import {ModalOneButton, ModalOverlay} from "../../components";
import {AddStudentInformation, AddTerm, PaySpeakingLessonPackage} from "../../services/Market/MarketService";
import useToken from "../../hooks/useToken";
import {useSelector} from "react-redux";
import {BuyPostponePackage} from "../../services/Market/MarketPostponeService";
import {useLayout} from "../../store/LayoutProvider";
import useWindowSize from "../../hooks/useWindowSize";

const Checkout = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenHook = useToken();
    const { member } = useAuth();
    const { setCurrentPage } = useLayout();
    const { width} = useWindowSize();

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const selectedTermId = useSelector(state => state.termStore.termId) || false;
    const isMobile = location.pathname.includes("mobile_market_checkout");
    const memberInfo = sessionStorage.getItem("m") ? (parseMobileKeyToJson(sessionStorage.getItem("m"))) : member;
    const packageInfo = sessionStorage.getItem("p") ? decodeToBase64AndUri(sessionStorage.getItem("p")) : false;

    // Form servise gönderildikten sonra bekleme durumunu kontrol eden state
    const [loading, setLoading] = useState(false);
    // Kredi Kartı bilgilerinin tutulduğu state
    const [creditCardInfo, setCreditCardInfo] = useState({
        cardName: "",
        cardNumber: "",
        cvv: "",
        cardMonth: { id: -1, name: strings.market.checkout.month },
        cardYear: { id: -1, name: strings.market.checkout.year },
        priceInfo: {},
    });
    const [invoiceInfo, setInvoiceInfo] = useState({
        name: "",
        email: "",
        address: "",
        memberId: memberInfo?.MemberId
    });
    // Ödeme durumunun başarılı olup/olmadığını kontrol eden state
    const [successfulPayment, setSuccessfulPayment] = useState(false);
    // Ödeme sırasında herhangi bir validasyon hatası var ise, bu hataları tutan state
    const [errors, setErrors] = useState([]);
    // Mobil görünümde açılan bottom slider'ın açık/kapalı olma durumu set ediliyor
    const [openBottomSheet, setOpenBottomSheet] = useState(false);

    // Kredi kartı bilgilerini güncellemek için kullanılan fonksiyon
    const changeCreditCardInfo = (values) => {
        setCreditCardInfo({ ...creditCardInfo, ...values });
    }

    // Fatura bilgilerini tutan state'i güncellemek için kullanılan fonksiyon
    const changeInvoiceInfo = (values) => {
        setInvoiceInfo({ ...invoiceInfo, ...values });
    }

    // Modalın kapanma durumunu kontrol eden fonksiyon
    const handleCloseModal = () => {
        closeModal(modalOverlayRef, modalRef);
        setErrors(error => []);
    }

    // Kredi kartı bilgilerini validate eden fonksiyon
    const validateCreditCardAndInvoiceForm = (invoiceInfo) => {
        let valid = true;

        //check form elements
        if (creditCardInfo.cardNumber.length < 15) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_number]);
        }

        if (creditCardInfo.cardName.length < 4) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_name]);
        }

        if (creditCardInfo.cardMonth.id === -1) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_month]);
        }

        if (creditCardInfo.cardYear.id === -1) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_year]);
        }

        if (creditCardInfo.cvv.length < 3) {
            valid = false;
            setErrors(error => [...error, strings.market.checkout.errors.card_cvv]);
        }

        if (invoiceInfo.name.length < 4) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.name]);
        }

        if (invoiceInfo.email.length < 4 || !validateEmail(invoiceInfo.email)) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.email]);
        }

        if (invoiceInfo.address.length < 4) {
            valid = false;
            setErrors(error => [...error, strings.market.information.errors.address]);
        }

        return valid;
    }

    // Dönem oluşturma için model oluşturan fonksiyon.
    const createTermInfo = (orderId) => {
        const todayDay = new Date().getDay();
        let today = new Date();

        //sunday, monday, tuesday
        let days = 3;

        //wednesday && todayDay
        if (todayDay === 3 || todayDay === 4) days = 5;
        //friday && saturday
        if (todayDay === 5 || todayDay === 6) days = 4;

        let startDate = formatDateMarket(addDaysToDate(today, days));

        return {
            beginDate: startDate,
            status: 1,
            orderId: orderId,
            memberId: memberInfo?.MemberId,
            createUserId: memberInfo?.MemberId,
            productOptions: {
                "categoryId": packageInfo?.selectedProduct?.categoryId,
                "minute": packageInfo?.selectedProduct?.minute,
                "monthId": packageInfo?.selectedProduct?.monthId,
                "dayCount": packageInfo?.selectedProduct?.dayCount
            },
            memberStatus: +memberInfo?.Status,
            salesType: +memberInfo?.Status === MemberStatus.Candidate ? SalesTypes.FirstSale : SalesTypes.ExtraPackage
        }
    }

    // Ortak kredi kartı modeli oluşturuluyor
    const createCreditCardModel = () => {
        return {
            number: creditCardInfo.cardNumber,
            name: creditCardInfo.cardName,
            month: creditCardInfo.cardMonth.name,
            year: creditCardInfo.cardYear.name.slice(2),
            cvc: creditCardInfo.cvv,
            cardType: "credit",
            isSecurity: false //Default
        }
    }

    const handlePayment = async () => {
        // Input alanlarına girilen değerlerin başındaki ve sonundaki boşlukları temizliyoruz.
        const trimmedInvoiceInfo = Object.fromEntries(
            Object.entries(invoiceInfo).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
        );
        setInvoiceInfo(trimmedInvoiceInfo);

        if(validateCreditCardAndInvoiceForm(trimmedInvoiceInfo)) {
            setLoading(true);

            await createInvoiceInfo();

            packageInfo?.source === MarketSourceTypes.SPEAKING_LESSON
                ? payPackage()
                : payRight()
        } else {
            openModal(modalOverlayRef, modalRef);
            setOpenBottomSheet(false);
        }
    }

    // Paket açma için ödemenin servise gönderildiği fonksiyon
    const payPackage = async ()  => {

        // Uygulanan kampanyalardan sadece kampanya id'leri filtreleniyor
        const campaignIds = packageInfo?.discountInformation?.discounts?.map(d => d.campaignId) || [];

        // Satış tipi belirleniyor
        const salesType= +memberInfo?.Status === MemberStatus.Candidate
            ? SalesTypes.FirstSale
            : SalesTypes.ExtraPackage

        const model = {
            brandId: +process.env.REACT_APP_BRAND_ID,
            memberId: memberInfo?.MemberId,
            salesType: salesType,
            productId: packageInfo?.selectedProduct?.productId,
            price: packageInfo?.selectedProduct?.productDetail?.priceDetail.priceWithoutFormat,
            // İndirim var ise indirim uygulanmış KDV'siz fiyat, yok ise indirimsiz KDV'siz fiyat gönderiliyor
            discountPrice: packageInfo?.discountInformation?.totalPrice,
            discountRate: packageInfo?.discountInformation?.totalDiscount,
            bankPosId: 1, //Default Garanti Bankası
            createUser: memberInfo?.MemberId,
            installment: 0, // Default Tek Çekim
            taxValue: packageInfo?.selectedProduct?.productDetail?.priceDetail?.taxRate,
            sourceType: isMobile ? SourceTypes.Mobile : SourceTypes.Web,
            // İndirim var ise indirim uygulanmış KDV tutarı, yok ise indirimsiz KDV fiyatı gönderiliyor
            taxPrice: packageInfo?.selectedProduct?.productDetail?.priceDetail.taxPriceWithoutFormat,
            // !!!!ÖDEMENİN ÇEKİLDİĞİ FİYAT!!!!
            totalPrice: packageInfo?.discountInformation?.totalPrice,
            campaignIdList: campaignIds,
            isRecurringPayment: false, //Default
        };

        model.creditCardDetail = createCreditCardModel();
        const result = await PaySpeakingLessonPackage(model, "");

        let paymentStatus;
        let redirectUrl;
        let errorCode;

        if (result.statusCode === 200) {
            paymentStatus = true;
            redirectUrl = "market.successful"
            errorCode = "";

            let orderId = result.message.replace(/"/g, "");
            setSuccessfulPayment(successfulPayment => true);

            let termInfo = createTermInfo(orderId);

            const termResult = await AddTerm(termInfo, "");

            if (termResult.statusCode !== 200) {
                errorCode = strings.market.checkout.term_error;
            }

            await tokenHook.generateToken(memberInfo?.MemberId);

        } else {
            paymentStatus = false;
            redirectUrl = "market.failed";
            errorCode = result.message.replace(/"/g, "");
        }

        setLoading(false);

        if (isMobile) {
            const data = {
                "payment_status": paymentStatus,
                "day_count": packageInfo?.selectedProduct.dayCount,
                "error_code": errorCode
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data))
        } else {
            navigate(url(redirectUrl), { state: { "errorCode": errorCode } });
        }
    }

    // Ek hak satın alma servisi
    const payRight = async () => {

        let model = {
            addedPostponeRightCount: packageInfo?.selectedProduct?.rightCount,
            sourceType: isMobile ? SourceTypes.Mobile : SourceTypes.Web,
            userType: 0, //member
            createUserId: memberInfo?.MemberId,
            payPackage: {
                brandId: parseInt(process.env.REACT_APP_BRAND_ID),
                memberId: memberInfo?.MemberId,
                termId: selectedTermId,
                salesType: SalesTypes.AdditionalRight, //6
                productId: packageInfo?.selectedProduct?.additionalProductPriceDetails?.productId,
                price: packageInfo?.selectedProduct?.additionalProductPriceDetails?.priceWithoutFormat,
                discountPrice: packageInfo?.selectedProduct?.additionalProductPriceDetails?.discountPriceWithoutFormat,
                discountRate: packageInfo?.selectedProduct?.additionalProductPriceDetails?.discountRate,
                bankPosId: 5, //Default İş Bankası
                createUser: memberInfo?.MemberId,
                installment: 0, //Default One Shot
                taxValue: packageInfo?.selectedProduct?.additionalProductPriceDetails?.taxRate,
                sourceType: isMobile ? SourceTypes.Mobile : SourceTypes.Web,
                taxPrice: packageInfo?.selectedProduct?.additionalProductPriceDetails?.taxPriceWithoutFormat,
                totalPrice: packageInfo?.selectedProduct?.additionalProductPriceDetails?.totalPriceWithoutFormat,
                campaignIdList: [],
                isRecurringPayment: false
            }
        }

        model.payPackage.creditCardDetail = createCreditCardModel();
        const result = await BuyPostponePackage(model);

        let paymentStatus;
        let redirectUrl;
        let errorCode;

        if (result.status === 200) {
            paymentStatus = true;
            redirectUrl = "market.successful"
            errorCode = "";
            setSuccessfulPayment(successfulPayment => true);

            await tokenHook.generateToken(memberInfo?.MemberId);
        } else {
            paymentStatus = false;
            redirectUrl = "market.failed";
            errorCode = result.content.replace(/"/g, "");
        }

        setLoading(false);
        //if request comes from mobile app
        if (isMobile) {
            const data = {
                "payment_status": paymentStatus,
                "error_code": errorCode
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(data))
        } else {
            navigate(url(redirectUrl), { state: { "source": MarketSourceTypes.POSTPONE_RIGHT, "errorCode": errorCode } });
        }
    }

    const createInvoiceInfo = async () => {
        const model = {
            name: invoiceInfo.name,
            email: invoiceInfo.email,
            address: invoiceInfo.address,
            memberId: memberInfo?.MemberId
        }

        await AddStudentInformation(model, "");
    }

    // Sayfaya route üzerinden ulaşılmaya çalışılırsa (Paket Seçim ekranından gelinmemiş ise) dashboard'a gönderiliyor.
    useEffect(() => {
        if(!packageInfo) {
            navigate(url("dashboard"));
        }
    }, [])

    // Web görünümünde sidebar kısmında ödeme bilgilerinin yazdığı bölüm set ediliyor
    useEffect(() => {
        if(width >= 768 && !isMobile)
            setCurrentPage(
                <CheckoutInfo
                    changeCreditCardInfo={changeCreditCardInfo}
                    creditCardInfo={creditCardInfo}
                    packageInfo={packageInfo}
                    handlePayment={handlePayment}
                    successfulPayment={successfulPayment}
                    buttonLoading={loading}
                />
            );
    }, [creditCardInfo, invoiceInfo, width, loading, successfulPayment]);

    useEffect(() => {
        if (isMobile) {
            const page = {
                "second_page": true
            }
            window.ReactNativeWebView.postMessage(JSON.stringify(page));
        }
    }, [])

    return (
        <>
            <div className={classNames("", {
                "main-content-mobile": isMobile,
                "main-content": !isMobile
            })}>
                <CreditCard
                    packageInfo={packageInfo}
                    memberInfo={memberInfo}
                    creditCardInfo={creditCardInfo}
                    changeCreditCardInfo={changeCreditCardInfo}
                />

                <Invoice
                    packageInfo={packageInfo}
                    memberInfo={memberInfo}
                    changeInvoiceInfo={changeInvoiceInfo}
                    invoiceInfo={invoiceInfo}
                />

                {width < 768 &&
                    <CheckoutInfoSticky
                        changeCreditCardInfo={changeCreditCardInfo}
                        packageInfo={packageInfo}
                        handlePayment={handlePayment}
                        successfulPayment={successfulPayment}
                        buttonLoading={loading}
                        openBottomSheet={openBottomSheet}
                        setOpenBottomSheet={setOpenBottomSheet}
                    />
                }

                <div className={classNames("left-margin-bottom", {
                    "!h-[300px]": width < 768
                    })}
                ></div>
            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef}/>
            <ModalOneButton
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.market.speakingLesson.information_messages.modal_title}
                message={errors.join("")}
                buttonText={strings.market.okay_button}
                buttonClick={handleCloseModal}
                closeClick={() => setErrors(error => [])}
            />
        </>
    );
}

export default Checkout;