import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Default_Teacher } from "../../assets/svg";
import { Audio, closeModal, Loading, ModalOverlay, ModalTwoButtons, openModal, TermDropDown } from "../../components";
import { EventLogs, MemberStatus, TermStatusTypes } from "../../components/Constants";
import { getApplicationDirection } from "../../components/Utility";
import useAnalytics from "../../hooks/useAnalytics";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { url } from "../../routes/utility";
import { GetTeacherDetailsService } from "../../services/Teacher/TeacherService";
import { TeacherTimeChange } from "./components";

const TeacherDetails = () => {

  const strings = useLocalization();
  const analytics = useAnalytics();
  const { member, token } = useAuth();
  const navigate = useNavigate();
  const { teacherId } = useParams();
  const selectedTermId = useSelector(state => state.termStore.termId);

  const terms = (typeof member.Terms !== "undefined") && JSON.parse(member.Terms).filter(term => term.Status === 1);
  const selectedTerm = terms && terms.find(term => term.TermId === selectedTermId);

  const language = useSelector(state => state.localizationStore.language);

  const modalRef = useRef();
  const modalOverlayRef = useRef();

  const [loading, setLoading] = useState(true);
  const [teacherData, setTeacherData] = useState();

  const getTeacherDetail = async () => {
    setLoading(true);
    const result = await GetTeacherDetailsService(teacherId, token);
    let resultMsg = JSON.parse(result.message);

    if (result.statusCode !== 200) {

      if (resultMsg)
        analytics.useAnalytics("Error_Occured", { error: `${resultMsg}` });
      else {
        analytics.useAnalytics("Error_Occured", {
          error: `An unexpected error occurred.`,
        });
      }

    } else {
      setTeacherData(resultMsg);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTeacherDetail();

    analytics.useAnalytics(EventLogs.TEACHER_DETAIL_SCREEN_VIEW);
  }, []);

  return (
    <div className={`main-content ${getApplicationDirection+(language) ? 'md:mr-7' : 'md:ml-7'}`}>
      <div className="left-page">

        {+member?.Status === MemberStatus.Active && (
            <div className="mt-5 box-section px-[10px] xs:px-[10px]">
              <TermDropDown terms={terms} />
            </div>
        )}

        {loading && <Loading />}

        {!loading && (
          <>
            {teacherData && (
              <div className="mt-5 transparent-section text-start text-sm">
                <div className="flex">
                  <div className="min-w-[90px] mr-2 sm:ml-4">
                    <img
                      className="rounded-full"
                      src={(teacherData.profilePictureUrl === "" || teacherData.profilePictureUrl === null) ? Default_Teacher : teacherData.profilePictureUrl}
                      width="90"
                      height="90"
                      alt=""
                    />
                  </div>
                  {/* Eğitmen Adı, Doğum Yeri, Yaşı */}
                  <div className="w-full">
                    <p className="font-bold text-base">
                      {teacherData.fullName.replace(" (Sanal Egitmen)", "")}
                    </p>
                    <hr className="my-2" />
                    <p className="mb-3">
                      <span className="font-bold text-secondary">
                        {strings.teachers.teacher_details.birth_place}:{" "}
                      </span>
                      {teacherData.birthPlace}
                    </p>
                    <p>
                      <span className="font-bold text-secondary">
                        {strings.teachers.teacher_details.age}:{" "}
                      </span>
                      {teacherData.age}
                    </p>
                  </div>
                </div>

                {/* Eğitmen Ses Kaydı Alanı */}
                {(teacherData.soundUrl !== null && teacherData.soundUrl !== "") && (
                    <div className="mt-2">
                      <Audio src={teacherData.soundUrl} />
                    </div>
                )}

                {(+member?.Status !== MemberStatus.Active) && (
                  <div className="btn-outer-div my-4">
                    <button
                      className="button secondary-button"
                      onClick={() => {
                        openModal(modalOverlayRef, modalRef);
                        analytics.useAnalytics(EventLogs.TAKE_LESSON_FROM_TEACHER);
                      }}
                    >
                      {strings.teachers.teacher_details.buy_lesson_from_teacher}
                    </button>
                  </div>
                )}

                {/* Eğitmenin hakkında kısmı */}
                {(teacherData.about !== null || teacherData.about !== "") && (
                  <>
                    <p className="font-bold text-secondary mt-4">{strings.teachers.teacher_details.about}</p>
                    <p className="mt-4">{teacherData.about}</p>
                  </>
                )}

                {/* Eğitmenin üniversite ve departman bilgileri */}
                {(teacherData.university !== null || teacherData.university !== "") && ( 
                  <>
                    <p className="font-bold text-secondary mt-4">{strings.teachers.teacher_details.education}</p>
                    <p className="mt-4">
                      <strong>{teacherData.university}</strong> <br />
                      {teacherData.department}
                    </p>
                  </>
                )}

                {/* Eğitmenin ilgi alanları */}
                {teacherData.interests.length > 0 && (
                  <>
                    <p className="font-bold text-secondary mt-4">{strings.teachers.teacher_details.interest_fields}</p>
                    <div className="mt-4 text-center sm:text-start">
                      {teacherData.interests.map((interest, index) => (
                        <button className="word-button mt-1" key={index}>
                          {interest}
                        </button>
                      ))}
                    </div>
                  </>
                )}
              </div>
            )}

            {+member?.Status !== MemberStatus.Active && (
              <>
                  {/* --- Modal --- */}
                  <ModalOverlay ref={modalOverlayRef} />
                  <ModalTwoButtons
                    ref={modalRef}
                    title={strings.auth.information_messages.modal_title}
                    overlayRef={modalOverlayRef}
                    message={strings.teachers.teacher_details.not_student_content}
                    buttonText1={strings.teachers.teacher_details.not_student_buy_button}
                    buttonClick1={() => { 
                      analytics.useAnalytics(EventLogs.REVIEW_PACKAGES_CLICK);
                      navigate(url('market.packagecatalog'));
                    }}
                    buttonText2={strings.teachers.teacher_details.not_student_cancel_button}
                    buttonClick2={() => {closeModal(modalOverlayRef, modalRef);}}
                  />
              </>
            )}

            {+member?.Status === MemberStatus.Active && selectedTerm.Status === TermStatusTypes.Active && (
                <TeacherTimeChange
                    teacherId={teacherId}
                />
            )}

            <div className="left-margin-bottom"></div>
          </>
        )}
      </div>
    </div>
  );
};

export default TeacherDetails;
