import React from 'react';
import { FlexibleSchedule, LiveLesson, MeasurableResults, MentorSupport, NativeTeacher } from '../../../assets/svg';
import { useLocalization } from '../../../hooks/useLocalization';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getApplicationDirection } from '../../../components/Utility';

const AdvantagesSection = () => {

  const strings = useLocalization();

  const language = useSelector(state => state.localizationStore.language);

  const advantages = [
    {
      icon: LiveLesson,
      title: strings.corporate_customer.advantages.live_classes_title,
      description: strings.corporate_customer.advantages.live_classes_description
    },
    {
      icon: NativeTeacher,
      title: strings.corporate_customer.advantages.native_teacher_title,
      description: strings.corporate_customer.advantages.native_teacher_description
    },
    {
      icon: FlexibleSchedule,
      title: strings.corporate_customer.advantages.flexible_schedule_title,
      description: strings.corporate_customer.advantages.flexible_schedule_description
    },
    {
      icon: MeasurableResults,
      title: strings.corporate_customer.advantages.measurable_results_title,
      description: strings.corporate_customer.advantages.measurable_results_description
    },
    {
      icon: MentorSupport,
      title: strings.corporate_customer.advantages.mentor_support_title,
      description: strings.corporate_customer.advantages.mentor_support_description
    }
  ];

  return (
    <section className="mt-4">
      <div className="space-y-4">
        {advantages.map((advantage, index) => (
          <div 
            key={index}
            className="flex items-start transparent-section"
          >
            <div className={classNames("flex-shrink-0 w-12 h-12 p-2 items-start bg-blue/5 text-blue/5 rounded-[10px]", {
              'ml-4': getApplicationDirection(language),
              'mr-4': !getApplicationDirection(language),
            })}>
              <img src={advantage.icon} alt={advantage.title} className="w-full h-full" />
            </div>
            <div className='flex flex-col items-start'>
              <p className="text-[18px] font-bold">{advantage.title}</p>
              <p className='text-[16px]'>{advantage.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AdvantagesSection; 