import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { FixSentence, Refresh } from "../../../../../assets/svg";
import { LoadingSpinner } from "../../../../../components/Loading";
import { PromptAnalysisFlash } from "../../../../../services/AIUseCase/AIUseCasePythonService";
import AILoader from "../../AIPractice/AILoader";
import { RevertSessionLogs } from "../../../../../services/AIUseCaseHistory/AIUseCaseHistoryService";
import { getApplicationDirection } from "../../../../../components/Utility";
import classNames from "classnames";
import { useSelector } from "react-redux";

const UserChatBubble = ({ 
    content, 
    messageId, 
    onRefresh, 
    isLoading,
    isReadOnly = false,
    isLastMessage = false,
    fixSentencePrompt = null,
    endOfPrompt = false,
    isContinueSession = null,
    isAutoCorrect = false,
    setWrongSentences,
    chatbotLoading = false,
    sessionId = null,
    lastAiMessageContent = null,
    onGrammarCheckComplete = null,
    timestamp = null,
    isRead = ""
}) => {

    const language = useSelector(state => state.localizationStore.language);

    const [grammarCheck, setGrammarCheck] = useState(null);
    const [isGrammarChecking, setIsGrammarChecking] = useState(false);

    // Loading durumu sadece grammar check için
    const showLoading = !isLoading && isGrammarChecking;
    // Değerlendirme sonucu gösterimi için
    const showAssessment = !isLoading && grammarCheck && !isGrammarChecking;
    
    const handleRefresh = () => {
        onRefresh?.(messageId);
        setGrammarCheck(null);

        if(sessionId)
            RevertSessionLogs({
                aiUseCaseSessionId: sessionId,
                aiUseCaseSessionLogId: messageId
            })
                .then()
                .catch();
    };

    const checkGrammar = async (isAutoCheck = false) => {
        if (!content || !fixSentencePrompt || isLoading) return;
        
        setIsGrammarChecking(true);
        try {
            let prompt = fixSentencePrompt.replaceAll('##sentence##', content);
            if(lastAiMessageContent)
                prompt = prompt.replaceAll('##lastMessageContent##', lastAiMessageContent);
            const result = await PromptAnalysisFlash(prompt);
            
            if (result.status === 200) {
                try {
                    // Remove ```json and ``` from content
                    const cleanContent = result.content
                        .replace(/```json/g, '')
                        .replace(/```/g, '')
                        .trim();
                        
                    const response = JSON.parse(cleanContent);
                    setGrammarCheck({...response, isAutoCheck});
                } catch (parseError) {
                    setGrammarCheck(null);
                }
            }
        } catch (error) {
            setGrammarCheck(null);
        } finally {
            setIsGrammarChecking(false);
        }
    };

    // Grammar check için kullanılan useEffect
    useEffect(() => {
        if (isLastMessage) {
            checkGrammar(true); // Otomatik kontrol
        }
    }, [content, fixSentencePrompt, isLoading, isLastMessage]);

    // Grammar check sonucunu parent'a bildiren useEffect
    useEffect(() => {
        if (grammarCheck && isLastMessage) {
            onGrammarCheckComplete?.(grammarCheck.isGrammarCorrect);
        }
    }, [grammarCheck, isLastMessage, onGrammarCheckComplete]);

    // Yanlış cevapları wrongSentences array'ine ekler
    useEffect(() => {
        if (grammarCheck && !grammarCheck.isGrammarCorrect && grammarCheck.correctAnswer && grammarCheck.isAutoCheck) {
            setWrongSentences?.(prev => [...prev, content]);
        }
    }, [grammarCheck, content]);

    return (
        <div dir="ltr" className={classNames("flex flex-row justify-end items-start my-1 gap-1", {
            "pl-2" : getApplicationDirection(language),
            "pr-2" : !getApplicationDirection(language)
        })}>
            {/* Grammar Check İkonu */}
            <div className="mt-2 w-6 h-6 flex items-center justify-center">
                {showLoading ? (
                    <LoadingSpinner />
                ) : showAssessment && (
                    <span 
                        className={`material-symbols-outlined text-2xl ${
                            grammarCheck?.isGrammarCorrect ? 'text-green' : 'text-red'
                        }`}
                        style={{
                            fontVariationSettings: "'FILL' 1"
                        }}
                    >
                        {grammarCheck?.isGrammarCorrect ? 'check_circle' : 'cancel'}
                    </span>
                )}
            </div>

            {/* Mesaj Kutusu ve Düzeltme Container'ı */}
            <div className="max-w-[80%]">
                <motion.div 
                    initial={{ x: 150, scale: 0 }}
                    animate={{ x: 0, scale: 1 }}
                    transition={{ duration: 0.3 }}
                    className={`relative bg-[#E9F1FD] rounded-2xl rounded-tr-sm ${
                        !grammarCheck?.isGrammarCorrect && grammarCheck?.correctAnswer?.length > 1
                            ? 'rounded-b-none' 
                            : ''
                    } px-4 py-3`}
                >
                    {isLoading ? (
                        <div className="w-6">
                            <AILoader color="#309DFF" />
                        </div>
                    ) : (
                        <>
                            <p className="text-sm text-base-text">
                                {content}
                            </p>
                            <div className="flex justify-end items-center gap-1 mt-1">
                                {timestamp && (
                                <p className="text-base-text-light text-[12px] text-right">{timestamp}</p>
                                )}
                                {isRead !== "" && (
                                    <div className="flex justify-end">
                                        <span className={`material-symbols-outlined text-primary text-[22px] ${isRead ? 'text-primary' : 'text-base-text-light'}`}>
                                            {isRead ? 'done_all' : 'done'}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {!isReadOnly && !endOfPrompt && (
                                <div className="flex flex-row items-center gap-1 mt-2">
                                    <button 
                                        onClick={handleRefresh}
                                        className={`rounded-full ${(showLoading || chatbotLoading) ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'}`}
                                        aria-label="Retry message"
                                        disabled={showLoading || chatbotLoading}
                                    >
                                        <img 
                                            src={Refresh} 
                                            className="w-[18px] h-[18px]"
                                            alt="refresh"
                                        />
                                    </button>

                                    {isContinueSession !== null && !isAutoCorrect && (
                                        <button 
                                            onClick={() => {
                                                setIsGrammarChecking(true);
                                                checkGrammar(false); // Manuel kontrol
                                            }}
                                            className={`rounded-full ${showLoading || grammarCheck?.correctAnswer || grammarCheck?.isGrammarCorrect ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-80'}`}
                                            aria-label="Fix sentence"
                                            disabled={showLoading || grammarCheck?.correctAnswer || grammarCheck?.isGrammarCorrect}
                                        >
                                            <img 
                                                src={FixSentence} 
                                                className="w-[18px] h-[18px]"
                                                style={{
                                                    filter: grammarCheck?.isGrammarCorrect || grammarCheck?.correctAnswer?.length > 1
                                                        ? 'invert(40%) sepia(95%) saturate(1752%) hue-rotate(199deg) brightness(100%) contrast(101%)'
                                                        : 'none'
                                                }}
                                                alt="fix-sentence"
                                            />
                                        </button>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </motion.div>

                {/* Yanlış cümle düzeltmesi - Mesaj kutusunun altında */}
                {!grammarCheck?.isGrammarCorrect && grammarCheck?.correctAnswer?.length > 1 && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        transition={{ duration: 0.2 }}
                        className="bg-[#FEF8F2] rounded-b-xl px-4 py-3 w-full -mt-[1px]"
                    >
                        <p className="text-[#F7A766] text-sm">
                            {grammarCheck.correctAnswer}
                        </p>
                    </motion.div>
                )}
            </div>
        </div>
    );
};

export default UserChatBubble;