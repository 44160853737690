import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {Schedule_Calendar} from "../../assets/img";
import {
    AttendanceReport,
    DailyLessonHorizontal,
    DailySentenceHorizontal,
    EducationProgram,
    ImproveBySpeaking,
    LearnWithVideos,
    QuizTest,
    Resource
} from "../../assets/svg";
import {closeModal, HorizontalMenuButton, ModalOverlay, ModalTwoButtons, openModal} from "../../components";
import {MemberStatus} from "../../components/Constants";
import {getApplicationDirection} from "../../components/Utility";
import useAnalytics from "../../hooks/useAnalytics";
import useAuth from "../../hooks/useAuth";
import {useLocalization} from "../../hooks/useLocalization";
import useToken from "../../hooks/useToken";
import {url} from "../../routes/utility";

const SpeakingLessonHome = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const { member } = useAuth();
    const tokenHook = useToken();
    const language = useSelector(state => state.localizationStore.language);

    const modalRef = useRef();
    const modalOverlayRef = useRef();

    /*
        Tıklama yapan kullanıcı aktif ise aktif eğitim paketleri sayfasına yönlendirme yapılıyor.
        Tıklama yapan kullanıcı pasif veya aday ise markette bulunan eğitim paketleri sayfasına yönlendiriliyor.
    */
    const handleNavigateEducationPackages = () => {
        let path = "speakinglesson.educationpackages";

        if(+member.Status !== MemberStatus.Active){
            path = "market.educationpackages"
        }
        navigate(url(path));
    }

    // Genel yönlendirme kontrollerini yapan fonksiyon
    const handleNavigate = (event, navigateUrl, termControl, additionalFunc) => {
        // Eventi analytics'e gönderir
        analytics.useAnalytics('Speaking_Lesson', { screen_name: event });
        // Eventi data layer'a gönderir
        window.customDataLayer.push({ event: 'Speaking_Lesson', screen_name: event });
        // yönlendirmeyi yapar

        // Ekstra bir fonksiyon tetiklenmeyecek ise
        if(!additionalFunc) {
            if(termControl) {
                if(parseInt(member.TermsCount) > 0){
                    navigate(url(navigateUrl));
                } else {
                    openModal(modalOverlayRef, modalRef);
                }
            } else {
                navigate(url(navigateUrl));
            }
        } else {
            additionalFunc();
        }
    }

    useEffect(() => {
        // regenerate token
        tokenHook?.generateToken(member?.MemberId);
    }, []);

    const menuItems = [
        {
            title: strings.speaking_lesson.speaking_lesson_user.lesson_of_the_day,
            condition: true,
            classnames: "",
            actions: {
                event: 'gunun_dersi_clicked',
                navigate: 'speakinglesson.dailylesson',
                termControl: false,
                additionalFunc: false
            },
            icon: DailyLessonHorizontal
        },
        {
            title: strings.speaking_lesson.speaking_lesson_user.practice_with_ai,
            condition: true,
            actions: {
                event: 'ai_pratik_clicked',
                navigate: 'speakinglesson.ai',
                termControl: false,
                additionalFunc: false
            },
            icon: ImproveBySpeaking
        },
        {
            title: strings.video_practice.title,
            condition: true,
            actions: {
                event: 'videolarla_pratik_clicked',
                navigate: 'videopractice',
                termControl: false,
                additionalFunc: false
            },
            icon: LearnWithVideos
        },
        {
            title: strings.speaking_lesson.speaking_lesson_user.daily_sentence,
            condition: true,
            classnames: "",
            actions: {
                event: 'cumle_kalibi_clicked',
                navigate: 'speakinglesson.dailysentence',
                termControl: true,
                additionalFunc: false
            },
            icon: DailySentenceHorizontal
        },
        {
            title: strings.speaking_lesson.speaking_lesson_user.quiz_test,
            condition: true,
            classnames: "",
            actions: {
                event: 'quiz_clicked',
                navigate: 'speakinglesson.quiz',
                termControl: true,
                additionalFunc: false
            },
            icon: QuizTest
        },
        {
            title: strings.speaking_lesson.speaking_lesson_user.term_schedule,
            condition: true,
            classnames: "",
            actions: {
                event: 'ders_takvimi_clicked',
                navigate: 'speakinglesson.termschedule',
                termControl: true,
                additionalFunc: false
            },
            icon: Schedule_Calendar
        },
        // {
        //     title: strings.speaking_lesson.speaking_lesson_user.progress_report,
        //     condition: MemberStatus.Active !== +member?.Status,
        //     classnames: "md:hidden",
        //     actions: {
        //         event: 'gelisim_izleme_clicked',
        //         navigate: 'speakinglesson.progressreport',
        //         termControl: false,
        //         additionalFunc: false
        //     },
        //     icon: ProgressChart
        // },
        {
            title: strings.speaking_lesson.speaking_lesson_user.participation_report,
            condition: true,
            classnames: "",
            actions: {
                event: 'derse_katilim_clicked',
                navigate: 'speakinglesson.attendancereport',
                termControl: false,
                additionalFunc: false
            },
            icon: AttendanceReport
        },
        {
            title: strings.speaking_lesson.speaking_lesson_user.education_packages,
            condition: true,
            classnames: "",
            actions: {
                event: 'egitim_paketleri_clicked',
                navigate: 'educationpackages',
                termControl: false,
                additionalFunc: handleNavigateEducationPackages
            },
            icon: EducationProgram
        },
        {
            title: strings.dashboard.student.all_sources,
            condition: true,
            classnames: "",
            actions: {
                event: 'tum_kaynaklar_clicked',
                navigate: 'resources',
                termControl: false,
                additionalFunc: false
            },
            icon: Resource
        },
    ]

    return (
        <>
            <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
                <div className="onboarding-outer-div">
                    {menuItems.map((item, index) => (
                        item.condition && (
                            <HorizontalMenuButton
                                key={index}
                                title={item.title}
                                icon={item.icon}
                                classnames={item.classnames}
                                onclick={() => handleNavigate(
                                    item.actions.event,
                                    item.actions.navigate,
                                    item.actions.termControl,
                                    item.actions.additionalFunc
                                )}
                            />
                        )
                    ))}
                </div>
                <div className="left-margin-bottom"></div>
            </div>

            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.support.main_page.not_student_content}
                buttonText1={strings.support.main_page.not_student_buy_button}
                buttonClick1={() => {
                    navigate(url("market.packagecatalog"));
                }}
                buttonText2={strings.support.main_page.not_student_cancel_button}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </>
    )
}

export default SpeakingLessonHome