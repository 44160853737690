import React, { useEffect, useRef, useState } from "react";
import { Failed, QuizNotFound } from "../../assets/svg";
import { closeModal, Loading, ModalOneButton, ModalOverlay, openModal, TermDropDown } from "../../components";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { GetBookQuizListByTermId, GetRandomQuestions } from "../../services/Quiz/QuizService";
import { QuizList, QuizQuestions } from "./components";
import {useSelector} from "react-redux";
import {EventLogs, MemberStatus} from "../../components/Constants";
import useAnalytics from "../../hooks/useAnalytics";
import {useParams, useNavigate} from "react-router-dom";
import { url } from "../../routes/utility";

const QuizHome = () => {

    const strings = useLocalization();

    const { member } = useAuth();
    const { bookUnitId } = useParams();
    const analytics = useAnalytics();
    const navigate = useNavigate();

    const terms = typeof (member.Terms) !== "undefined" && JSON.parse(member.Terms);
    const selectedTermId = useSelector(state => state.termStore.termId);

    const firstVisit = useRef(true);
    const modalRef = useRef();
    const modalOverlayRef = useRef();

    const [loading, setLoading] = useState(true);
    const [termId, setTermId] = useState(false);
    const [selectedQuiz, setSelectedQuiz] = useState({});
    const [bookName, setBookName] = useState(null);
    const [books, setBooks] = useState([]);
    const [isRequestFailed, setIsRequestFailed] = useState(false)
    const [quizInformation, setQuizInformation] = useState([]);

    const changeTerm = (term) => {
        setTermId(term.TermId);
        setLoading(loading => true);
    };

    const getBookQuizListByTermId = async (termId) => {
        setIsRequestFailed(isRequestFailed => false);
        setBooks(books => []);
        const result = await GetBookQuizListByTermId(termId);
        if (result.statusCode === 200) {
            setBooks(JSON.parse(result.message));
        } else {
            if (result.message !== "bookQuizList not found by book unit id!")
                setIsRequestFailed(isRequestFailed => true)
        }
        setLoading(loading => false);

    }

    const getRandomQuestions = async (model, bookName) => {
        setLoading(loading => true)
        const result = await GetRandomQuestions(model);
        if (result.statusCode === 200) {
            let tmpData = JSON.parse(result.message)
            tmpData = tmpData.map((item, index) => {
                const updatedQuestionAnswers = item.questionAnswers.map((answer, index) => ({
                    ...answer,
                    id: index + 1
                }));

                return {
                    ...item,
                    index: index + 1,
                    questionAnswers: updatedQuestionAnswers
                };
            });
            setQuizInformation(tmpData);

            //Quiz'in başladığı loglanıyor.
            analytics.useAnalytics(EventLogs.LESSON_QUIZ_STARTED, { quiz_name: bookName });
        } else {
            openModal(modalOverlayRef, modalRef);
        }
        setLoading(loading => false);

    }

    const sendToEndPoint = (selectedQuiz) => {
        let model = {
            bookQuizId: selectedQuiz.bookQuizId,
            dailyBook: selectedQuiz.dailyBook,
            date: selectedQuiz.dailyBook ? selectedQuiz.date : null
        }
        getRandomQuestions(model, selectedQuiz.bookName);
    }

    useEffect(() => {
        if (Object.keys(selectedQuiz).length > 0) {
            setBookName(bookName => selectedQuiz.bookName);
        }
    }, [selectedQuiz])

    useEffect(() => {
        termId && getBookQuizListByTermId(termId)
    }, [termId])

    useEffect(() => {
        if(!firstVisit.current)
            //Quiz ekranında sayfa paket değiştirildikçe log atılıyor.
            analytics.useAnalytics(EventLogs.LESSON_QUIZ_LIST_PACKAGE_CHANGE);
    }, [selectedTermId]);

    useEffect(() => {
        //Sayfa yüklendikten sonra logları doğru bir şekilde atabilmek için ilk yüklenmede kontrol sağlıyoruz.
        firstVisit.current = false;
    }, [])

    /*
    Eğer route'dan gelen bir parametre var ise ilgili bookunit id'ye göre seçim yaptırılıyor.
*/
    useEffect(() => {
        if(books.length > 0 && bookUnitId) {
            const tempSelectedQuiz = books.find(b => b.bookUnitId === +bookUnitId);
            setSelectedQuiz(tempSelectedQuiz);
            sendToEndPoint(tempSelectedQuiz);
        }
    }, [bookUnitId, books]);

    return (
        <>
            {(Object.keys(selectedQuiz).length === 0 || loading) && (
                <div className="mt-5 main-content">
                    <div className="left-page">
                        <div className="mb-5 gray-section bg-title-bg px-[10px] xs:px-[10px] text-start">
                            <TermDropDown terms={terms} changeTerm={changeTerm} />
                        </div>

                        {
                            loading && <Loading />
                        }
                        {
                            !loading && (
                                <>
                                    {(!isRequestFailed && books.length > 0) && (
                                        <>

                                            <div className="transparent-section mt-5 text-sm text-start">
                                                <h5 className="text-secondary text-[17px]">{strings.speaking_lesson.quiz.title}</h5>
                                                <p className="mt-1 font-bold text-center max-w-lg mx-auto px-5">
                                                    {strings.speaking_lesson.quiz.description}
                                                </p>
                                                <QuizList
                                                    books={books}
                                                    termId={termId}
                                                    setSelectedQuiz={setSelectedQuiz}
                                                    sendToEndPoint={sendToEndPoint}
                                                />
                                            </div>
                                            <p
                                                className="text-[#999] text-[14px] flex items-center justify-center flex-wrap mt-2"
                                                dangerouslySetInnerHTML={{ __html: strings.speaking_lesson.quiz.answered_quiz }}
                                            >
                                            </p>
                                        </>
                                    )}

                                    {isRequestFailed && +member?.Status === MemberStatus.Active && (
                                        <>
                                            <img
                                                className="mx-auto"
                                                src={Failed}
                                                alt=""
                                                width="100"
                                                height="100"
                                            />
                                            <p className="w-full text-center p-4">{strings.general_information_messages.an_error_occured}</p>
                                        </>
                                    )}

                                    {isRequestFailed && +member?.Status !== MemberStatus.Active &&(
                                        <div className="gray-section">
                                            <p className="w-full text-center p-4">
                                                {strings.speaking_lesson.quiz.not_active_student}
                                            </p>

                                            <div className="my-5 btn-outer-div">
                                                <button 
                                                    className="button primary-button" 
                                                    onClick={() => {
                                                        analytics.useAnalytics(EventLogs.REVIEW_PACKAGES_CLICK);
                                                        navigate(url('market.packagecatalog'));
                                                    }}
                                                >
                                                    {strings.teachers.teacher_details.not_student_buy_button}
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {(!isRequestFailed && books.length === 0) && (
                                        <>
                                            <img
                                                className="mx-auto"
                                                src={QuizNotFound}
                                                alt=""
                                                width="100"
                                                height="100"
                                            />
                                            <p className="w-full text-center p-4">{strings.speaking_lesson.quiz.no_book_found}</p>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                    <div className="left-margin-bottom"></div>
                </div>
            )}
            {(Object.keys(selectedQuiz).length > 0 && quizInformation.length > 0 && !loading) && (
                <QuizQuestions
                    quizId={selectedQuiz.bookQuizId}
                    bookName={bookName}
                    bookUnitId={selectedQuiz.bookUnitId}
                    quizInformation={quizInformation}
                />
            )}

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalOneButton
                ref={modalRef}
                title={strings.auth.information_messages.modal_title}
                message={strings.onboarding.information_messages.an_error_occured}
                overlayRef={modalOverlayRef}
                buttonText={strings.auth.form.okay_button}
                buttonClick={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
        </>
    )
}

export default QuizHome