import React, { useEffect } from 'react';
import { HeroSection, AdvantagesSection, CorporateForm, References } from './components';
import { EventLogs } from '../../components/Constants';
import useAnalytics from '../../hooks/useAnalytics';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const CorporateCustomer = () => {

  const { member } = useAuth();

  const analytics = useAnalytics();
  const navigate = useNavigate();
  
  useEffect(() => {
    analytics.useAnalytics(EventLogs.CORPORATE_CUSTOMER_SCREEN_VIEWED);

    if (+member?.IsCorporate) {
      navigate(-1);
    }
  }, []);
  
  return (
    <div className="main-content mt-5">
      <div className='left-page'>
        <HeroSection />
        <AdvantagesSection />
        <CorporateForm />
        <References />
        <div className='left-margin-bottom'></div>
      </div>
    </div>
  );
};

export default CorporateCustomer;