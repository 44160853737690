import { TermDropDown } from "../../components/TermDropDown";
import useAuth from "../../hooks/useAuth";
import useAnalytics from "../../hooks/useAnalytics";
import { useState, useEffect } from "react";
import { GetAttendanceReportService } from "../../services/SpeakingLesson/SpeakingLessonService";
import { useLocalization } from "../../hooks/useLocalization";
import { Accordion } from "./components/common";
import { getMemberType } from "../../services/Auth/AuthService";
import { useSelector } from "react-redux";
import { fixDateFormatForAl, getApplicationDirection } from "../../components/Utility";
import { useNavigate } from "react-router-dom";
import { EventLogs } from "../../components/Constants";
import { url } from "../../routes/utility";
const AttendanceReport = () => {

  const { member, token } = useAuth();

  const strings = useLocalization();
  const analytics = useAnalytics();
  const navigate = useNavigate();

  const memberType = getMemberType(member.Status);
  const terms = memberType !== 'Candidate' && JSON.parse(member.Terms);

  const language = useSelector(state => state.localizationStore.language);

  const [termId, setTermId] = useState(false);
  const [data, setData] = useState(false);

  //from child dropdown
  const changeTerm = term => {
    setTermId(term.TermId);
  };

  const getReport = async () => {
    const result = await GetAttendanceReportService(termId, token);
    if (result.statusCode === 200) {
      setData(JSON.parse(result.message));
    } else {
      if (result.message)
        analytics.useAnalytics('Error_Occured', { error: `${result.message}` });
      else {
        analytics.useAnalytics('Error_Occured', {
          error: `An unexpected error occurred.`,
        });
      }

    }
  }

  //if termId is selected
  useEffect(() => {
    if (termId && memberType !== 'Candidate') {
      getReport();
    }
  }, [termId])

  return (
    <>
      <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
        <div className="left-page">
        {memberType === 'Candidate' ? (
          <>
            <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-sm text-center">
              {strings.speaking_lesson.attendance_report.you_are_a_candidate}
            </div>
            <div className="my-5 btn-outer-div">
                <button 
                    className="button primary-button" 
                    onClick={() => {
                        analytics.useAnalytics(EventLogs.REVIEW_PACKAGES_CLICK);
                        navigate(url('market.packagecatalog'));
                    }}
                >
                    {strings.teachers.teacher_details.not_student_buy_button}
                </button>
            </div>
          </>
        ) : (
          <div className="mt-5 gray-section px-[10px] xs:px-[10px] text-start">
            <TermDropDown terms={terms} changeTerm={changeTerm} />
          </div>
        )}

          <div
              tabIndex="1"
              className="group outline-none text-sm mt-5"
          >
            <div className={`group accordion-div ${getApplicationDirection(language) ? 'pr-7' : 'pl-7'}`}>
              <div className={`accordion-header ${getApplicationDirection(language) ? 'right-0 mr-2 ml-auto rotate-180' : 'left-0 ml-2 mr-auto rotate-0'}`}>
                <span className="material-symbols-outlined text-base-text"> arrow_forward_ios </span>
              </div>
              <div className={`accordion-text ${getApplicationDirection(language) ? 'mr-2' : 'ml-2'}`}>
                {strings.speaking_lesson.attendance_report.report_1.title}
              </div>

            </div>
            <div className="accordion-content">
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.begin_date}</span> {memberType === 'Candidate' ? "-" : getApplicationDirection(language) ? data.beginDate : fixDateFormatForAl(data.beginDate)}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.total_lesson_count}</span> {memberType === 'Candidate' ? "-" : data.totalLessonCount}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.remaining_lesson_count}</span> {memberType === 'Candidate' ? "-" : data.reminingLessonCount || 0}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.attended_lesson_count}</span> {memberType === 'Candidate' ? "-" : (data.attendedLessonCount || 0)}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.missed_lesson_count}</span> {memberType === 'Candidate' ? "-" : (data.missedLessonCount || 0)}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.postpone_right_count}</span> {memberType === 'Candidate' ? "-" : (data.postponeRightCount || 0)}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.change_teacher_time_right_count}</span> {memberType === 'Candidate' ? "-" : (data.teacherHourChangeRightCount || 0)}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.missed_call_right_count}</span> {memberType === 'Candidate' ? "-" : (data.missedCallRightCount || 0)}</p>
              <p className="mb-3"><span className="font-bold">{strings.speaking_lesson.attendance_report.report_1.freeze_right_count}</span> {memberType === 'Candidate' ? "-" : (data.freezeRightCount || 0)}</p>
            </div>
          </div>

          <Accordion
              title={strings.speaking_lesson.attendance_report.report_2.title}
              tabindex={2}
              data={data.noAnswerLessonDates || []}
          />

          <Accordion
              title={strings.speaking_lesson.attendance_report.report_3.title}
              tabindex={3}
              data={data.postponedLessonDates || []}
          />

          <Accordion
              title={strings.speaking_lesson.attendance_report.report_4.title}
              tabindex={4}
              data={data.missedCallLessonDates || []}
          />

          <div className="left-margin-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default AttendanceReport;
