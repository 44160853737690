import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components';
import { useLocalization } from '../../../hooks/useLocalization';
import { GetCompanyLogos } from '../../../services/CorporateCustomer/CorporateCustomerService';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getApplicationDirection } from '../../../components/Utility';

const References = () => {

  const strings = useLocalization();

  const language = useSelector(state => state.localizationStore.language);
  const isArabic = getApplicationDirection(language);

  const [loading, setLoading] = useState(true);
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    GetCompanyLogos()
      .then(result => {
        if(result.status === 200) {
          setLogos(result.content);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="mt-5">
      <h2 className="text-[20px] font-bold text-center mb-1">
        {strings.corporate_customer.references.title}
      </h2>
      {loading && <Loading />}
      {!loading && logos.length > 0 && (
        <div className="overflow-hidden relative py-4">
          <motion.div
            className="flex"
            initial={{ x: isArabic ? '0%' : '0%' }}
            animate={{ x: isArabic ? '100%' : '-100%' }}
            transition={{
              x: {
                duration: 30,
                repeat: Infinity,
                repeatType: "loop",
                ease: "linear"
              }
            }}
          >
            {(isArabic ? [...logos].reverse() : logos).map((logo, index) => (
              <div
                key={`first-${index}`}
                className="flex-shrink-0 w-[150px] h-[70px] flex items-center justify-center"
              >
                <img
                  src={logo}
                  alt="company logo"
                  className="max-w-full max-h-full object-contain rounded-[10px]"
                />
              </div>
            ))}
            {(isArabic ? [...logos].reverse() : logos).map((logo, index) => (
              <div
                key={`second-${index}`}
                className="flex-shrink-0 w-[150px] h-[70px] flex items-center justify-center"
              >
                <img
                  src={logo}
                  alt="company logo"
                  className="max-w-full max-h-full object-contain rounded-[10px]"
                />
              </div>
            ))}
          </motion.div>
        </div>
      )}
    </section>
  );
};

export default References;