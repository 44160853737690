import React, {useEffect, useRef, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import {
  Calendar,
  Exchange,
  Holiday,
  Hourglass,
  Missed_Call,
  Paper,
  Reload,
  Support_Speech_Bubble,
} from "../../assets/svg";
import {url} from "../../routes/utility";
import useAuth from "../../hooks/useAuth";
import {getMemberType} from "../../services/Auth/AuthService";
import {closeModal, HorizontalMenuButton, ModalOverlay, ModalTwoButtons, openModal} from "../../components";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {getApplicationDirection} from "../../components/Utility";
import {MemberStatus, TermStatusTypes} from "../../components/Constants";

const SupportHome = () => {

  const strings = useLocalization();
  const { member, token } = useAuth();
  const navigate = useNavigate();

  const [termStatus, setTermStatus] = useState(null);

  const selectedTerm = localStorage.getItem("selectedTerm");
  const terms = typeof(member.Terms) !== "undefined" && JSON.parse(member.Terms);
  const showButton = getMemberType(member.Status) === "Student" && termStatus === 1;

  const language = useSelector(state => state.localizationStore.language);

  const modalRef = useRef();
  const modalPassiveRef = useRef();
  const modalFutureRef = useRef();
  const modalPendingRef = useRef();
  const modalOverlayRef = useRef();

  const menuItems = [
    {
      title: strings.support.main_page.send_request,
      icon: Support_Speech_Bubble,
      showLink: true,
      showOnclick: false,
      path: "support.sendticket",
      onClick: () => {}
    },
    {
      title: strings.support.main_page.ticket_history,
      icon: Paper,
      showLink: true,
      showOnclick: false,
      path: "support.tickets",
      onClick: () => {}
    },
    {
      title: strings.support.main_page.holidays,
      icon: Holiday,
      showLink: true,
      showOnclick: false,
      path: "support.holidays",
      onClick: () => {}
    },
    {
      title: strings.support.main_page.postpone_lesson,
      icon: Calendar,
      showLink: showButton,
      showOnclick: !showButton,
      path: "support.postpone",
      onClick: () => openModalByMemberAndTermStatus(member.Status, termStatus)
    },
    {
      title: strings.support.main_page.teacher_lesson_time_change,
      icon: Exchange,
      showLink: showButton,
      showOnclick: !showButton,
      path: "support.changeteachertime",
      onClick: () => openModalByMemberAndTermStatus(member.Status, termStatus)
    },
    {
      title: strings.support.main_page.missed_call,
      icon: Missed_Call,
      showLink: showButton,
      showOnclick: !showButton,
      path: "support.missedcall",
      onClick: () => openModalByMemberAndTermStatus(member.Status, termStatus)
    },
    {
      title: strings.support.main_page.frozen_lesson,
      icon: Hourglass,
      showLink: showButton,
      showOnclick: !showButton,
      path: "support.freeze",
      onClick: () => openModalByMemberAndTermStatus(member.Status, termStatus)
    },
    {
      title: strings.support.main_page.change_password,
      icon: Reload,
      showLink: true,
      showOnclick: false,
      path: "support.changepassword",
      onClick: () => {}
    }
  ]

  /*
    * Öğrenci durumu aday ise Aday Öğrenci modalı açılır. (Özellikleri kullanabilmek için eğitim paketi alın)
    * Öğrenci durumu aday değil ise ve dönem durumu pasif ise Pasif Dönem modalı açılır. (Özellikleri kullanabilmek için seçili dönem aktif olmalı)
    * Öğrenci durumu aday değil ise ve dönem durumu gelecek ise Gelecek Dönem modalı açılır. (Özellikleri kullanabilmek için seçili dönem gelecek olmalı)
  */
  const openModalByMemberAndTermStatus = (memberStatus, termStatus) => {
    if(+memberStatus === MemberStatus.Candidate || +memberStatus === MemberStatus.Passive) {
      openModal(modalOverlayRef, modalRef);
    } else {
      if(termStatus === TermStatusTypes.Inactive) {
        openModal(modalOverlayRef, modalPassiveRef);
      } else if (termStatus === TermStatusTypes.Future) {
        openModal(modalOverlayRef, modalFutureRef);
      } else if (termStatus === TermStatusTypes.Pending){
        openModal(modalOverlayRef, modalPendingRef);
      }
    }
  }

  useEffect(() => {
    if(terms && selectedTerm){
      const tmpTerm = terms.find((term) => term.TermId === selectedTerm.slice(1, -1));
    
      setTermStatus(termStatus => tmpTerm?.Status);
    }
  }, [terms, token])

  return (
    <>
      <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
        <div className="left-page">
          <div className="onboarding-outer-div">
            {menuItems.map((item, index) => (
                <HorizontalMenuButton
                    key={index}
                    title={item.title}
                    icon={item.icon}
                    showLink={item.showLink}
                    showOnclick={item.showOnclick}
                    onclick={item.onClick}
                    link={item.path.length > 0 && url(item.path)}
                />
            ))}
          </div>

          {/* --- Modal --- */}
          <ModalOverlay ref={modalOverlayRef}/>
          <ModalTwoButtons
              ref={modalRef}
              title={strings.auth.information_messages.modal_title}
              overlayRef={modalOverlayRef}
              message={strings.support.main_page.not_student_content}
              buttonText1={strings.support.main_page.not_student_buy_button}
              buttonClick1={() => {
                navigate(url("market.packagecatalog"));
              }}
              buttonText2={strings.support.main_page.not_student_cancel_button}
              buttonClick2={() => {
                closeModal(modalOverlayRef, modalRef);
              }}
          />
          <ModalTwoButtons
              ref={modalPassiveRef}
              overlayRef={modalOverlayRef}
              title={strings.auth.information_messages.modal_title}
              message={strings.support.main_page.passive_term_message}
              buttonText1={strings.support.main_page.choose_active_term}
              buttonClick1={() => {
                navigate(url("dashboard"));
              }}
              buttonText2={strings.support.main_page.not_student_cancel_button}
              buttonClick2={() => {
                closeModal(modalOverlayRef, modalPassiveRef);
              }}
          />
          <ModalTwoButtons
              ref={modalFutureRef}
              overlayRef={modalOverlayRef}
              title={strings.auth.information_messages.modal_title}
              message={strings.support.main_page.future_term_message}
              buttonText1={strings.support.main_page.choose_active_term}
              buttonClick1={() => {
                navigate(url("dashboard"));
              }}
              buttonText2={strings.support.main_page.not_student_cancel_button}
              buttonClick2={() => {
                closeModal(modalOverlayRef, modalFutureRef);
              }}
          />
          <ModalTwoButtons
              ref={modalPendingRef}
              overlayRef={modalOverlayRef}
              title={strings.auth.information_messages.modal_title}
              message={strings.support.main_page.pending_term_message}
              buttonText1={strings.modal.okey}
              buttonClick1={() => {
                closeModal(modalOverlayRef, modalPendingRef);
              }}
              buttonText2={strings.support.main_page.not_student_cancel_button}
              buttonClick2={() => {
                closeModal(modalOverlayRef, modalPendingRef);
              }}
          />

          <div className="left-margin-bottom"></div>
        </div>
      </div>
    </>
  );
};

export default SupportHome;
